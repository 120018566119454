/* Header */
.header-container {
  position: absolute;
  width: 100%;
  transform: translate(-50%);
  left: 50%;
  max-width: 1180px;
  padding: 0 20px;
  margin: auto;
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  padding: 15px 0;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  height: 50px;
  transition: 1.2s;
}
.logo:hover {
  transform: rotateY(180deg);
}
.logo-text {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.2;
  color: #dee0ee;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.logo-text-container span {
  display: block;
  color: #7eadf0;
}
.header-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  color: #dee0ee;
  text-transform: uppercase;
}
.menu li {
  margin: 0 15px;
  list-style-type: none;
}
a {
  color: inherit;
  text-decoration: none;
}
.social-list {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}
.social-list li {
  margin: 0 10px;
  transition: 0.3s;
}
.social-list li:hover {
  transform: rotate(-12deg);
}
.social-list img {
  height: 20px;
}
li {
  position: relative;
}
li a span::after,
li a span::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  bottom: -40%;
  display: block;
  background: none repeat scroll 0 0 transparent;
  height: 2px;
  width: 0;
  background-image: linear-gradient(90deg, #359ef6, #025cdd);
  transition: width 0.2s ease 0s, left 0.2s ease 0s;
}

li a span:hover::after {
  width: 100%;
  left: 0;
}
li a span:hover:before {
  width: 80%;
}
li a span:before {
  opacity: 0.4;
  bottom: -8px;
  transform: translate(-50%, 1px);
}
.mobile-menu {
  width: 30px;
  height: 24px;
  opacity: 0.6;
  cursor: pointer;
  display: none;
  position: absolute;
  right: 2%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.modile-menu-line {
  top: 50%;
  width: 100%;
  height: 4px;
  border-radius: 10px;
  background-color: #1796ff;
  display: block !important;
  transition: 0.3s;
}
.mobile-menu-window {
  display: none;
  width: 100%;
  height: 0;
  position: absolute;
  top: 100%;
  background-color: #141935;
  transition: 0.3s;
  overflow: hidden;
  z-index: 2;
}
@media screen and (max-width: 980px) {
  header {
    position: fixed !important;
    z-index: 2;
  }
  .header-container,
  .header-content {
    background-color: #161c3aab;
    height: 90px;
    padding: 0;
  }
  .header-content div:not(.header-logo) {
    display: none;
  }
  header .header-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .mobile-menu {
    display: flex !important;
  }
  .mobile-menu-window,
  .mobile-menu-window .header-menu {
    display: block !important;
  }
  .mobile-menu-window .header-menu {
    padding-top: 15px;
  }
  .menu:not(footer .menu) {
    font-size: 30px;
    flex-direction: column;
  }
  .menu li:not(footer li) {
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
/* Main */
main {
  width: 100%;
  position: relative;
  top: 120px;
  padding-bottom: 120px;
}
.background-logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 144px;
  font-weight: 900;
  text-transform: uppercase;
  color: rgba(50, 58, 109, 0.3);
  margin-top: 130px;
  user-select: none;
}
.main-section {
  text-align: center;
  margin-bottom: 110px;
}
.main-logo {
  position: relative;
  padding-top: 130px;
  font-size: 56px;
  font-weight: 300;
  color: #1796ff;
  z-index: 1;
  margin-bottom: 30px;
  text-align: center;
}
.main-logo span {
  color: white;
}
.main-info {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 94%;
  max-width: 1180px;
  text-align: left;
  margin-bottom: 30px;
}
.btns-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  display: inline-block;
  width: 170px;
  height: 50px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 10px;
  cursor: pointer;
  background-image: linear-gradient(180deg, #359ef6, #0662df);
}
.btn:hover {
  background-image: linear-gradient(180deg, #0662df, #359ef6);
}
.bordered-btn:hover {
  background-image: linear-gradient(to right, #359ef6, #0662df);
}
.btn:not(.bordered-btn) {
  margin: 0 14px;
}
.btn-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 50px;
  border-radius: 10px;
  margin: 0 14px;
  background: linear-gradient(to right, #359ef6, #0662df);
}
.bordered-btn {
  background: #242b51;
  width: 166px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bordered-btn img {
  height: 10px;
  padding-right: 10px;
}
.assets-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  margin-top: 60px;
}
.assets-container {
  width: 90%;
  color: white;
  max-width: 1140px;
}
.table {
  font-size: 14px;
}
.tr {
  display: flex;
  align-items: center;
  min-height: 40px;
  height: 40px;
  background-color: rgba(35, 78, 226, 0.1);
}
.tr:nth-child(2n-1) {
  background-color: #263163;
}
.tr:first-child {
  background-image: linear-gradient(to right, #1b3a8a, #32439f);
  border-radius: 10px 10px 0 0;
}
.th {
  color: #9eaacc;
  text-transform: uppercase;
  width: 20%;
}
.th:first-child,
.td:first-child {
  width: 60%;
}
.td,
.th {
  padding-left: 20px;
  padding-right: 20px;
}
.td a {
  margin-left: 12px;
  color: #68a1ff;
  white-space: nowrap;
}
.td .balance {
  width: 100%;
}
.td:nth-child(2) {
  width: 20%;
}
.faq {
  margin-bottom: 50px;
}
.faq-section {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.faq-list-container {
  width: 90%;
  max-width: 1140px;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.faq-item {
  padding: 25px;
  border-radius: 10px;
  cursor: pointer;
}
.faq-question {
  display: flex;
  align-items: center;
  font-weight: 700;
}
.faq-icon {
  height: 40px;
  margin-right: 14px;
}
.faq-active {
  background-image: linear-gradient(
    to right,
    rgba(22, 35, 77, 0.8),
    rgba(29, 46, 106, 0.8)
  );
}
.faq-answer {
  font-size: 14px;
  text-align: left;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s;
}
.faq-active > .faq-answer {
  transition: max-height 2s;
  padding-top: 20px;
  padding-right: 40px;
  max-height: 500px;
}
.circulation-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circulation-container {
  max-width: 1140px;
  width: 90%;
}
.circulation-canvas {
  width: 100%;
  max-width: 1140px;
}
.canvas-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.circulation-container h3 {
  padding-left: 10px;
}
@media screen and (max-width: 980px) {
  .background-logo {
    font-size: 100px;
  }
}

@media screen and (max-width: 680px) {
  .btns-container {
    flex-direction: column;
  }
  .btns-container a {
    margin: 10px 0px;
  }
}
@media screen and (max-width: 520px) {
  .background-logo {
    font-size: 70px;
  }
  .address,
  .balance {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inherit;
    width: 90%;
  }
  .copy {
    margin-left: 0 !important;
  }
  .tr .td:nth-child(2),
  .tr .th:nth-child(2) {
    padding: 0;
    margin-right: 10%;
  }
  .td:first-child {
    padding-left: 10px;
  }
  .td {
    padding: 0;
  }
  .th:first-child,
  .td:first-child {
    width: 50%;
  }
  .tr .th:first-child {
    padding-left: 10px;
  }
}
@media screen and (max-width: 400px) {
  .main-logo {
    font-size: 26px;
  }
  .background-logo {
    font-size: 50px;
  }
}
/* Footer */
footer {
  margin-top: 120px;
  padding: 64px 0;
  background-image: linear-gradient(180deg, #1c2c66, #192859);
  outline: rgba(255, 255, 255, 0.08) solid 2px;
  outline-offset: -2px;
}
.footer-container .header-logo {
  margin-bottom: 40px;
}
.social-container {
  padding-top: 55px;
  margin-bottom: 35px;
}
.copyright {
  font-size: 12px;
  color: #919abc;
  text-align: center;
}
