@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
* {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  box-sizing: border-box;
}
body, html, #root {
  height: 100%;
  background-image: url('./img/stars.svg'), url(./img/radiance.png);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
img:not(.logo) {
  pointer-events: none;
  user-select: none;
}
p {
  line-height: 1.8;
  letter-spacing: .5px;
  color: #9eaacc;
}
h3 {
  margin-bottom: 20px;
  font-size: 18px;
  color: white;
}